/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { getRelativePlayerIndex, LocationContext, LocationDescription, MaterialContext } from '@gamepark/react-game'
import { Coordinates, Location } from '@gamepark/rules-api'
import { LocationType } from '@gamepark/trek12-amazonie/material/LocationType'
import { explorationMapDescription } from '../../material/ExplorationMapDescription'
import { PlayerReminder } from './PlayerReminder'

export class ExplorationMapDescription extends LocationDescription {

  borderRadius = 1
  alwaysVisible = true
  content = PlayerReminder
  extraCss = css`pointer-events: none`

  getSize() {
    return { width: 6.5, height: 2 }
  }

  getLocations(context: MaterialContext): Location[] {
    return context.rules.players.map((player) => ({
      type: LocationType.ExplorationMap,
      player
    }))
  }

  getCoordinates(location: Location, context: LocationContext): Coordinates {
    const players = context.rules.game.players
    const base = getBaseCoordinates(players.length)
    const index = getRelativePlayerIndex(context, location.player)

    let additionalX = ((explorationMapDescription.width + 0.9) * ((index) % 3))
    if (players.length === 2 && index === 1) additionalX += 5

    return {
      ...base,
      x: base.x + additionalX,
      y: base.y + (index < 3 ? 0 : 20),
      z: 10
    }
  }
}

function getBaseCoordinates(players: number): Coordinates {
  switch (players) {
    case 1:
      return { x: -25.3, y: -3.5, z: 0 }
    case 2:
    case 3:
      return { x: -25.3, y: 6.5, z: 0 }
    case 4:
    case 5:
    case 6:
    default:
      return { x: -25.3, y: -3.5, z: 0 }
  }
}