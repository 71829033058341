export enum MaterialType {
  ExplorationMap = 1,
  ObservationCard,
  NumberCard,
  Cross,
  GreenDice,
  YellowDice,
  ExpeditionNodeValue,
  ObservationNumber,
  ObservationScores,
  ScoreRing,
  Path,
  AreaNode,
  PathwayScore,
  AreaScore,
  SubTotal,
  Total,
  Piranha,
  DangerTick,
  Spider
}
