import greenDice1 from './dice/green/1.jpg'
import greenDice2 from './dice/green/2.jpg'
import greenDice3 from './dice/green/3.jpg'
import greenDice4 from './dice/green/4.jpg'
import greenDice5 from './dice/green/5.jpg'
import greenDice6 from './dice/green/6.jpg'
import yellowDice0 from './dice/yellow/0.jpg'
import yellowDice1 from './dice/yellow/1.jpg'
import yellowDice2 from './dice/yellow/2.jpg'
import yellowDice3 from './dice/yellow/3.jpg'
import yellowDice4 from './dice/yellow/4.jpg'
import yellowDice5 from './dice/yellow/5.jpg'
import ara from './exploration/ara.jpg'
import animalBack from './exploration/back.jpg'
import butterfly from './exploration/butterfly.jpg'
import jaguar from './exploration/jaguar.jpg'
import poisonDartFrog from './exploration/poison-dard-frog.jpg'
import oustiti from './exploration/pygmy-marmoset.jpg'
import aecSnake from './exploration/rainbow-boa.jpg'
import tamarin from './exploration/tamarind.jpg'
import toucan from './exploration/toucan.jpg'
import forest1 from './forest1.jpg'
import animalNumber0 from './number/0.jpg'
import animalNumber1 from './number/1.jpg'
import animalNumber2 from './number/2.jpg'
import animalNumber3 from './number/3.jpg'
import animalNumber4 from './number/4.jpg'
import animalNumber5 from './number/5.jpg'
import animalNumber6 from './number/6.jpg'
import animalNumberBack from './number/back.jpg'
import MinusIcon from './icons/minus.jpg'
import MinIcon from './icons/min.jpg'
import MaxIcon from './icons/max.jpg'

const Images = {
  animalBack, animalNumberBack,
  aecSnake, ara, butterfly, jaguar, oustiti, poisonDartFrog, tamarin, toucan,
  animalNumber0, animalNumber1, animalNumber2, animalNumber3, animalNumber4, animalNumber5, animalNumber6,
  forest1,
  yellowDice0, yellowDice1, yellowDice2, yellowDice3, yellowDice4, yellowDice5,
  greenDice1, greenDice2, greenDice3, greenDice4, greenDice5, greenDice6,
  MinusIcon, MinIcon, MaxIcon
}

export default Images